<template>
  <v-btn
    small
    fab
    icon
    :color="computedButtonColorClass"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon>
      <slot></slot>
    </v-icon>
  </v-btn>
</template>

<script>
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";

export default {
  name: "ActionButton",
  inheritAttrs: false,
  mixins: [buttonSettingsMixin],
  props: {
    iconColorClass: {
      type: String,
      default: undefined
    }
  },
  computed: {
    computedButtonColorClass() {
      return this.iconColorClass ? this.iconColorClass : this.buttonColorClass;
    }
  }
};
</script>
